html[dir="rtl"] body {
  text-align: right; }
  html[dir="rtl"] body * {
    letter-spacing: 0; }

.home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field label.error {
  right: auto;
  left: 0; }

.home-section.succ_str .app_bx:hover .hvr_dv {
  text-align: right; }

.home-section .nav_box {
  flex-direction: row-reverse; }

.home-banner:after {
  right: auto;
  left: 0; }

.home-banner .inner_ .bnr_lf .bnr_lf_title h1 {
  font-size: 4vw; }

.home-banner .inner_ .bnr_lf .bnr_lf_title {
  padding-right: 0;
  padding-left: 2%; }

.home-banner .inner_ .bnr_lf .bnr_lf_title {
  width: 100%;
  padding: 0; }

.home-banner .bnr_nav {
  flex-direction: row-reverse; }

.home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt {
  padding-left: 0;
  padding-right: 6%; }

.home-section.ldr_shp .inner_:after {
  left: auto;
  right: 0; }

.home-section.ldr_shp .inner_ .bnr_nav {
  flex-direction: row-reverse;
  right: auto;
  left: 15%; }

.home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp ul li {
  padding-right: 0;
  padding-left: 50px; }

.home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp ul li span {
  margin-left: 0;
  margin-right: 10px; }

.home-section.nws_sec .inner_ .bnr_nav .nav_inr {
  flex-direction: row-reverse; }

.home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp:before {
  left: auto;
  right: 0; }

.home-banner:after {
  transform: translateX(-100%); }

.home-banner .inner_ .bnr_rt .bnr_img {
  transform-origin: right; }

.is-loaded .home-banner .inner_ .bnr_rt .bnr_img:after {
  transform: translateX(-100%); }

.home-section.em_yth .inner .bnr_nav {
  flex-direction: row-reverse; }

.home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .main_txt i img {
  transform: scaleX(1); }

.home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li:after {
  left: auto;
  right: -1em; }

@media only screen and (max-width: 768px) {
  .home-banner .inner_ .bnr_lf .bnr_lf_title h1 {
    font-size: 7vw; } }

@media only screen and (max-width: 767px) {
  .home-section.host_majlis .inner_ .host_majlis_rt {
    padding: 0; }
  .home-banner .banner_sec_rgt .inner_ .banner_btns .btn_wrp1 .btn_ {
    font-size: 4.2vw; }
  .home-banner .banner_sec_rgt .inner_ .banner_btns {
    bottom: -30%; }
  .home-section.prgrm_sec .inner_ .prgrm_sec_rgt .text_bx .quote_img {
    right: 0; }
  .home-section.ways_contr .contri_bx_inr .contri_bx .contri_bx_ico {
    margin-right: 0; }
  .home-section.ways_contr .contri_bx_inr .contri_bx .contri_bx_ico .img_ {
    background-position: right center; }
  .home-section.media_res .inner_ ul li:nth-of-type(2) {
    width: 100%; }
  .home-section.media_res .inner_ ul li:nth-of-type(1), .home-section.media_res .inner_ ul li:nth-of-type(3) {
    width: 100%; }
  .home-banner .inner .banner_main .banner_rgt, .home-banner .inner .banner_main .banner_md, .home-banner .inner .banner_main .banner_lf {
    width: 100%; }
  .home-banner .inner .banner_main .banner_rgt .cir_img_.img3_ {
    top: 50px; }
  .home-banner .inner .banner_main .banner_rgt .cir_img_.img4_ {
    right: auto; }
  .home-banner .inner .banner_main .banner_rgt .cir_img_.img2_ {
    top: 100px; }
  .home-banner .inner .banner_main .banner_rgt .cir_img_.img6_ {
    top: 30px;
    right: 15%; }
  .home-banner .inner .banner_main .banner_lf .cir_img_.img2_ {
    bottom: 100px;
    top: auto; }
  .home-banner .inner .banner_main .banner_lf .cir_img_.img4_ {
    bottom: 50px; }
  .home-banner .inner .banner_main .banner_lf .cir_img_.img5_ {
    bottom: 100px; }
  .home-banner .inner .banner_main .banner_lf .cir_img_.img1_ {
    right: auto; }
  .home-banner .inner .banner_main .banner_lf .cir_img_.img3_ {
    bottom: -62px;
    top: auto; }
  .home-banner .inner .banner_main .banner_rgt .rond_dv:first-of-type {
    left: 0;
    top: 15px; }
  .home-banner .inner .banner_main .banner_rgt .rond_dv:nth-of-type(2) {
    right: 35%;
    top: -38px; }
  .home-banner .inner .banner_main .banner_rgt .rond_dv:nth-of-type(3) {
    right: 0;
    top: 33px; }
  .home-banner .inner .banner_main .banner_rgt .rond_dv:nth-of-type(4) {
    right: 0;
    bottom: 62px; }
  .home-banner .inner .banner_main .banner_rgt .rond_dv:nth-of-type(6) {
    left: 47%;
    bottom: -88px; }
  .home-banner .inner .banner_main .banner_lf .rond_dv:first-of-type {
    left: 0;
    bottom: 34px;
    top: auto; }
  .home-banner .inner .banner_main .banner_lf .rond_dv:nth-of-type(2) {
    right: 51%;
    top: 50px; }
  .home-banner .inner .banner_main .banner_lf .rond_dv:nth-of-type(3) {
    right: 6%;
    bottom: 65px; }
  .home-banner .inner .banner_main .banner_lf .rond_dv:nth-of-type(4) {
    right: 44%;
    bottom: 120px; }
  .home-banner .inner .banner_main .banner_lf .rond_dv:nth-of-type(5) {
    left: 25%;
    bottom: 0; }
  .home-section.cntct_sec .inner_ .form_cntrol .form_bx .input_row .input-field label.error {
    left: 0;
    right: auto; } }
